"use strict";

/**
 * Created by Thiago Lima on 14/04/2020.
 */

(function () {
  'use strict';

  var groupsContainerLanguage = {
    header: {
      title: 'Defina os grupos a serem pesquisados',
      groupLabel: 'Grupo ',
      helper: {
        description: 'Os grupos são responsáveis por coletar as publicações e podem funcionar de forma independente ao Contexto. Você pode digitar os descritores desejados ou selecionar um modelo conforme as sugestões para marcas.',
        linkText: ' Saiba mais como utilizar os grupos.',
        link: "https://help.blip.ai/hc/pt-br/articles/18890292650647-Contexto-e-Grupos"
      }
    },
    body: {
      titleLabel: 'Insira o nome de uma marca ou assunto que o grupo irá coletar',
      placeholder: 'Insira o nome do grupo ou da marca',
      helper: {
        description: "Use v\xEDrgula, ponto e v\xEDrgula ou pressione \u201Center\u201D para separar os descritores. Para aprimorar sua configura\xE7\xE3o e ampliar sua coleta,",
        linkText: "veja como utilizar os operadores.",
        link: "https://help.blip.ai/hc/pt-br/articles/18955282819735"
      },
      addButton: 'Adicionar novo grupo'
    },
    suggestions: {
      organic: 'Orgânico',
      proprietary: 'Perfis',
      descriptorChangeAlert: {
        title: 'Atenção',
        text: 'Ao utilizar um grupo sugerido, você irá substituir os descritores inseridos manualmente e não será possível reverter essa ação. Deseja continuar?',
        notShowCheck: 'Não mostrar mais esse alerta'
      }
    },
    error: {
      titleDuplicated: 'Os títulos não podem ser repetidos'
    },
    modal: {
      removeGroup: {
        title: 'Deseja apagar esse grupo?',
        description: 'Ao apagar esse grupo e salvar a pesquisa, não será possível recuperá-lo. Deseja continuar?'
      }
    }
  };
  window.moduleExport({
    groupsContainerLanguage: groupsContainerLanguage
  }, typeof module !== 'undefined' && module);
})();